<template>
  <b-card>
    <b-row class="betting-form-container">
      <b-col cols="auto">
        <b-form-group>
          <b-form-radio-group
            class="button-checkbox"
            v-model="data.lotteryId"
            name="lottery"
            button-variant="outline-primary"
            buttons
          >
            <b-form-radio
              :value="lottery.id"
              v-for="(lottery, index) in lotteries"
              :key="`lottery-${index}-${lottery.id}`"
              buttons
            >
              <span>
                {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col md="6">
        <b-embed
          v-if="data.videoLink && videoType === 'link'"
          type="iframe"
          aspect="16by9"
          :src="data.videoLink"
          allowfullscreen
        ></b-embed>
        <div
          v-if="data.videoLink && videoType === 'iframe'"
          v-html="data.videoLink"
        ></div>
      </b-col>
      <b-col md="6">
        <validation-observer ref="liveSetupForm" #default="{ invalid }">
          <n-form-confirmation
            key="liveSetupForm"
            @submit="submit"
            :form="$refs.liveSetupForm"
            :disabled="invalid"
          >
            <n-input
              ref="input"
              :fields="fields"
              v-model="data"
              :initValue="initData"
            ></n-input>
            <b-row>
              <b-col cols="6">
                <n-button-loading
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1"
                  block
                  :loading="loading"
                  :disabled="invalid"
                  v-if="$can('update', 'live-setup')"
                >
                  {{ $t("button.saveChanges") }}
                </n-button-loading>
              </b-col>
              <b-col cols="6">
                <n-button-loading
                  type="button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="data.liveStatus ? `danger` : `success`"
                  class="mt-1"
                  block
                  :loading="loading"
                  :disabled="!data.id"
                  v-if="$can('update', 'live-setup')"
                  @submit="changeLiveStatus"
                >
                  {{
                    data.liveStatus
                      ? $t("button.stopLive")
                      : $t("button.goLive")
                  }}
                </n-button-loading>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BEmbed,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import Ripple from "vue-ripple-directive";
import formInput from "./formInput";
import Toast from "@/libs/toast";
import Repository from "@/repositories/RepositoryFactory";

const LotteryRepository = Repository.get("lottery");
const LotteryLiveRepository = Repository.get("lotteryLive");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BEmbed,

    NInput,
    NFormConfirmation,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        lotteryId: null,
        liveStatus: 0,
        videoLink: null,
      },
      videoType: "link",
      initData: {},
      lotteries: [],

      loading: false,
    };
  },
  watch: {
    "data.lotteryId"(value) {
      if (value) {
        this.show();
      }
    },
  },
  mounted() {
    this.getLottery();
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          if (data.length) {
            this.data.lotteryId = data[0].id;
          }
          this.lotteries = [...data];
        }
      });
    },
    show() {
      LotteryLiveRepository.show({
        lotteryId: this.data.lotteryId,
      }).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          this.initData = { ...data };
        }
      });
    },
    submit() {
      this.loading = true;
      LotteryLiveRepository.save(this.data)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
          }
          Toast.fire({
            icon: "success",
            title: this.$t("alert.saveSuccess"),
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeLiveStatus() {
      if (!this.data.lotteryId) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "md",
          okVariant: this.data.liveStatus ? "danger" : "success",
          okTitle: this.data.liveStatus
            ? this.$t("button.stopLive")
            : this.$t("button.goLive"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            LotteryLiveRepository.save({
              ...this.data,
              liveStatus: this.data.liveStatus ? 0 : 1,
            })
              .then((response) => {
                const data = response?.data?.data;
                if (data) {
                  this.data = { ...data };
                }
                Toast.fire({
                  icon: "success",
                  title: this.$t("alert.saveSuccess"),
                });
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
  },
  setup() {
    const fields = [...formInput];

    return { fields };
  },
};
</script>