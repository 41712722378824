import { render, staticRenderFns } from "./NTable.vue?vue&type=template&id=c7cf414e&scoped=true&"
import script from "./NTable.vue?vue&type=script&lang=js&"
export * from "./NTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7cf414e",
  null
  
)

export default component.exports