<template>
  <div>
    <result-menu></result-menu>

    <live-setting v-if="$can('read', 'live-setup')"></live-setting>

    <div v-if="$can('read', 'result-history')">
      <!-- Search Container Card -->
      <n-search-container
        :active="true"
        :show-header="false"
        v-model="params"
        @search="search"
        @reset="reset"
        :loading="loading"
      >
        <n-search-input ref="search" :fields="searchFields" v-model="params">
          <template #startDate>
            <div v-if="params.period != 'other'"></div>
          </template>
          <template #endDate>
            <div v-if="params.period != 'other'"></div>
          </template>
          <template #lotteryId="item">
            <b-form-group :label-for="item.field.key">
              <label>
                {{ $t(item.field.label) }}
              </label>
              <n-single-select
                :reduce="true"
                v-model="params.lotteryId"
                :selection-label="$i18n.locale == 'en' ? 'name' : 'nameKh'"
                :options="lotteries"
                :clearable="false"
              ></n-single-select>
            </b-form-group>
          </template>
          <template #lotteryTimeId="item">
            <b-form-group :label-for="item.field.key">
              <label>
                {{ $t(item.field.label) }}
              </label>
              <n-single-select
                :reduce="true"
                v-model="params.lotteryTimeId"
                :options="lotteryTimes"
                :clearable="false"
              ></n-single-select>
            </b-form-group>
          </template>
        </n-search-input>
      </n-search-container>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-0-5">
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
          >
            <template #cell(lotteryName)="data">
              {{
                $i18n.locale == "en"
                  ? data.item.lotteryName
                  : data.item.lotteryNameKh
              }}
            </template>
            <template #cell(lotteryTimeName)="data">
              {{
                moment(data.item.lotteryTimeName, "HH:mm")
                  .locale($i18n.locale)
                  .format("h:mm a")
              }}
            </template>
            <template #cell(date)="data">
              {{ data.item.date | formatDate("DD-MM-YYYY") }}
            </template>
            <template #cell(number)="data">
              <span class="text-danger">
                {{ data.item.number }}
              </span>
            </template>
            <template #cell(totalSteakRiel)="data">
              {{ data.item.totalSteakRiel | currency(116) }}
            </template>
            <template #cell(totalSteakUSD)="data">
              {{ data.item.totalSteakUSD | currency(840) }}
            </template>
            <template #cell(revenue)="data">
              <span
                :class="{
                  'text-danger': data.item.revenue < 0,
                  'text-positive': data.item.revenue > 0,
                }"
              >
                {{ data.item.revenue | currency(840) }}
              </span>
            </template>
            <template #cell(actions)="data">
              <b-link class="link-underline" @click="showResult(data.item)">
                {{ $t("button.showReult") }}
              </b-link>
            </template>
            <template #cell(isPublish)="data">
              <span
                class="badge badge-success badge-pill"
                v-if="data.item.isPublish"
              >
                {{ $t("status.publish") }}
              </span>
              <span class="badge badge-danger badge-pill" v-else>
                {{ $t("status.unpublish") }}
              </span>
            </template>
            <template #custom-foot>
              <b-tr v-if="!loading">
                <b-th colspan="5"></b-th>
                <b-th>
                  <h5 class="font-weight-bold">
                    {{ totalReport.totalSteakRiel | currency(116) }}
                  </h5>
                </b-th>
                <b-th>
                  <h5 class="font-weight-bold">
                    {{ totalReport.totalSteakUSD | currency(840) }}
                  </h5>
                </b-th>
                <b-th>
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-positive': totalReport.revenue >= 0,
                      'text-danger': totalReport.revenue < 0,
                    }"
                  >
                    {{ totalReport.revenue | currency(840) }}
                  </h5>
                </b-th>
                <b-th colspan="1"></b-th>
              </b-tr>
            </template>
          </n-table>
          <n-pagination
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
            ref="pagination"
          ></n-pagination>
        </div>
      </b-card>

      <result-detail ref="resultDetail"></result-detail>
    </div>
  </div>
</template>

<script>
import { BCard, BFormGroup, BRow, BCol, BLink, BTr, BTh } from "bootstrap-vue";
import ResultMenu from "@/views/result/ResultMenu.vue";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import LiveSetting from "./liveSetting/Index.vue";
import SearchInputs from "./searchInput";
import NSingleSelect from "@/components/NSingleSelect";
import { calculateDatesByPeriod } from "@/libs/helper";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import ResultDetail from "./resultDetail/Index.vue";
import TableFields from "./tableFields";
import vSelect from "vue-select";
import moment from "moment";

const LotteryRepository = Repository.get("lottery");
const LotteryResultRepository = Repository.get("lotteryResult");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BLink,
    BTr,
    BTh,

    vSelect,
    ResultMenu,
    LiveSetting,
    ResultDetail,
    NSearchContainer,
    NSearchInput,
    NSingleSelect,
    NTable,
    NPagination,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      this.calculateDate();
    },
    "params.lotteryId"(value) {
      if (value) {
        this.params.lotteryTimeId = null;
        this.getLotteryTime(value);
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      lotteries: [],
      lotteryTimes: [],
      items: [],
      totalReport: {},
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        period: this.$route.query.period || "today",
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        lotteryId: this.$route.query.lotteryId || null,
        lotteryTimeId: this.$route.query.lotteryTimeId || null,
      },
      moment,
    };
  },
  mounted() {
    this.getLottery();
    this.getData();
  },
  methods: {
    showResult(item) {
      this.$refs.resultDetail.show(item);
    },
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        let times = this.lotteries[index].times;
        times = times.map((element) => {
          return {
            ...element,
            name: moment(element.name, "HH:mm")
              .locale(this.$i18n.locale)
              .format("h:mm a"),
          };
        });
        this.lotteryTimes = [...times];
      }
    },
    calculateDate() {
      let dates;
      if (this.params.period == "other") {
        dates = calculateDatesByPeriod("today");
      } else {
        dates = calculateDatesByPeriod(this.params.period);
      }
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    reset() {
      this.$refs.search.reset();
      this.params.search = "";
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.period = "today";
      this.calculateDate();
      this.updateQuerySting();
      this.getData(this.page);
    },
    getData() {
      this.loading = true;
      if (!this.params.startDate || !this.params.endDate) {
        this.calculateDate();
      }
      LotteryResultRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
            this.getTotal();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      LotteryResultRepository.total({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response?.data?.data;
          if (data) {
            this.totalReport = { ...data };
          }
        })
        .catch(() => {});
    },
  },
  setup() {
    const fields = [...TableFields];
    const searchFields = [...SearchInputs];
    const resource = "bet-setting";

    return {
      fields,
      searchFields,
      resource,
    };
  },
};
</script>