<template>
  <div>
    <load-profile></load-profile>
    <div class="mb-2 sub-menu">
      <b-button
        :id="menu.routeName"
        :variant="
          $route.meta.navActiveTab == menu.active
            ? `primary`
            : `outline-primary`
        "
        v-for="menu in menus"
        :key="`menu-${menu.routeName}`"
        class="mr-1"
        @click="
          $router.push({
            name: menu.routeName,
            params: { id: $route.params.id },
          })
        "
        v-if="$can('read', menu.resource)"
      >
        {{ $t(menu.label) }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  data() {
    return {
      menus: [
        {
          routeName: "live-setup",
          active: "live-setup",
          label: "breadcrumb.liveSetup",
          resource: "live-setup",
        },
        {
          routeName: "define-result",
          active: "define-result",
          label: "breadcrumb.defineResult",
          resource: "define-result",
        },
        // {
        //   routeName: "live-bet",
        //   active: "live-bet",
        //   label: "breadcrumb.liveBet",
        //   resource: "live-bet",
        // },
      ],
      avialbleMenus: [
        {
          routeName: "live-setup",
          resource: "live-setup",
        },
        {
          routeName: "result-history",
          resource: "result-history",
        },
        {
          routeName: "define-result",
          resource: "define-result",
        },
        // {
        //   routeName: "live-bet",
        //   resource: "live-bet",
        // },
      ],
    };
  },
  mounted() {
    this.scrollToMenu();
  },
  methods: {
    scrollToMenu() {
      setTimeout(() => {
        const element = document.getElementById(this.$route.name);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }, 200);
    },
  },
  setup() {},
};
</script>