export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'lotteryName',
    label: 'field.lotteryName',
  },
  {
    key: 'lotteryTimeName',
    label: 'field.lotteryTime',
  },
  {
    key: 'isPublish',
    label: 'field.isPublish',
  },
  {
    key: 'date',
    label: 'field.date',
  },
  {
    key: 'totalSteakRiel',
    label: 'field.totalSteakRiel',
  },
  {
    key: 'totalSteakUSD',
    label: 'field.totalSteakUSD',
  },
  {
    key: 'revenue',
    label: 'field.revenue',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
