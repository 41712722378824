<template>
  <b-modal
    id="modal-result-detail"
    hide-footer
    centered
    :title="getModalTitle()"
    size="xl"
  >
    <div :class="data.lotteryId == 2 ? 'lottery-kh' : 'lottery-vn'">
      <b-row>
        <b-col md="6">
          <h6>
            {{ $t("field.date") }}: {{ data.date | formatDate("DD-MM-YYYY") }}
          </h6>
        </b-col>
      </b-row>
      <b-row
        v-for="channel in channels"
        :key="`channel-${channel.post}`"
        class="result-table"
      >
        <b-col cols="4" md="3" lg="2">
          <div class="result result-head">
            {{ channel.post }}
          </div>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          <b-row>
            <b-col
              class="result-grid"
              :md="calculateGird(channel.results.length)"
              v-for="(result, idx) in channel.results"
              :key="`result-${channel.post}-${result}-${idx}`"
            >
              <div class="result result-row" v-if="channel.post != 'Lo'">
                {{ result }}
              </div>
              <div class="result result-row" v-else>
                {{ getFirstLoDigit(result)
                }}<span class="text-primary">{{ getLastLoDigit(result) }}</span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
      channels: [],
    };
  },
  methods: {
    getModalTitle() {
      const title =
        this.$i18n.locale == "en"
          ? this.data.lotteryName
          : this.data.lotteryNameKh;

      const time = moment(this.data.lotteryTimeName, "HH:mm")
        .locale(this.$i18n.locale)
        .format("h:mm a");

      return title + " - " + time;
    },
    getFirstLoDigit(number) {
      number = number.toString();
      if (number.length == 3) {
        return number.charAt(0);
      }
      return "";
    },
    getLastLoDigit(number) {
      number = number.toString();
      if (number.length == 3) {
        return number.slice(-2);
      }
      return number;
    },
    calculateGird(length) {
      if (length == 3) {
        return 4;
      } else if (length == 2) {
        return 6;
      } else if (length == 1) {
        return 12;
      } else {
        return 3;
      }
    },
    mapResult() {
      let channels = [];

      this.data.posts.forEach((element) => {
        if (element.post != "Lo") {
          let resultTwo = element.resultTwo.split(",");
          let resultThree = element.resultThree.split(",");

          let mostResult =
            resultTwo.length > resultThree.length
              ? resultTwo.length
              : resultThree.length;

          for (let i = 0; i < mostResult; i++) {
            let channel = {
              post: `${element.post}${mostResult > 1 ? i + 1 : ""}`,
            };

            channel.results = [];
            if (resultTwo[i]) {
              channel.results.push(resultTwo[i]);
            }
            if (resultThree[i]) {
              channel.results.push(resultThree[i]);
            }

            channels.push(channel);
          }
        } else {
          let resultOther = element.resultOther.split(",");
          let resultThree = element.resultThree.split(",");
          let channel = {
            post: element.post,
            results: resultOther.concat(resultThree),
          };

          channels.push(channel);
        }
      });

      this.channels = [...channels];
    },
    show(data) {
      this.data = { ...data };
      this.mapResult();
      this.$bvModal.show("modal-result-detail");
    },
  },
};
</script>